import React, { forwardRef } from "react";
import "./index.css";

import binance from "src/assets/images/sixth/binance.png";
import sevenx from "src/assets/images/sixth/sevenx.png";
import okx from "src/assets/images/sixth/okx.png";
import mask from "src/assets/images/sixth/mask.png";
import foresight from "src/assets/images/sixth/foresight.png";
import Footer from "src/view/footer";
import { FifthPage } from "src/view/fifth";

type ChildProps = {};

export const SixthPage = forwardRef((props: ChildProps, ref) => {
  return (
    <>
      <div className="sixth">
        <div className="content">
          <div className="title">Investment Partners</div>
          <ul className="top">
            <li>
              <img src={binance} alt="" />
            </li>
            <li>
              <img src={sevenx} alt="" />
            </li>
            <li>
              <img src={okx} alt="" />
            </li>
          </ul>
          <div className="middle"></div>
          <ul className="bottom">
            <li>
              <img src={mask} alt="" />
            </li>
            <li>
              <img src={foresight} alt="" />
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  )
})
