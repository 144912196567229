import "./FourthPage.scss"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

type ChildProps = {};
export const FourthPage= forwardRef((props: ChildProps, ref) => {
    const tags = ['Gamfied Mining','Fair Launch Participation']
    useImperativeHandle(ref, () => ({
        startAnimation() {
            const element: any = document.querySelector('.fourth-animate-1');
            const element2: any = document.querySelector('.fourth-animate-2');
            const element3: any = document.querySelector('.fourth-animate-3');
            if (element) {
                void element.offsetWidth;
                element.classList.add('animate__bounceInLeft')
                element2.classList.add('animate__flipInX');
                element3.classList.add('animate__fadeInUp');
                element.style.opacity = 1
                element2.style.opacity = 1
                element3.style.opacity = 1
            }
        },
        removeAnimation() {
            const element: any = document.querySelector('.fourth-animate-1');
            const element2: any = document.querySelector('.fourth-animate-2');
            const element3: any = document.querySelector('.fourth-animate-3');
            if (element) {
                element.classList.remove('animate__bounceInLeft');
                element2.classList.remove('animate__flipInX');
                element3.classList.remove('animate__fadeInUp');
                element.style.opacity = 0
                element2.style.opacity = 0
                element3.style.opacity = 0
                void element.offsetWidth;
            }
        }
    }));
    const [innerWidth, setInnerWidth] = useState(0)
    useEffect(() => {
        const arrows = document.querySelectorAll('.arrow-1, .arrow-2, .arrow-3, .arrow-4');

        function startAnimation() {
            // 先给 arrow-1 添加动画
            arrows[0].classList.add('animate-1');

            setTimeout(() => {
                // arrow-1 动画结束后，给其他三个箭头添加动画
                arrows[1].classList.add('animate-2');
                arrows[2].classList.add('animate-3');
                arrows[3].classList.add('animate-4');

                setTimeout(() => {
                    // 移除动画类以便重新开始
                    arrows[0].classList.remove('animate-1');
                    arrows[1].classList.remove('animate-2');
                    arrows[2].classList.remove('animate-3');
                    arrows[3].classList.remove('animate-4');

                    // 1 秒后重新开始动画
                    setTimeout(startAnimation, 500);
                }, 1500); // arrow-2, arrow-3, arrow-4 动画时长
            }, 1200); // arrow-1 动画时长
        }

// 启动动画
        startAnimation();


        const handleResize = () => {
            setInnerWidth(window.innerWidth);
        };
        setInnerWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className={'fourth-page'}>
            <div className={'left-container fourth-animate-1 animate__animated'}>
                <div className={'frame-container'}>
                    <div className={'icon-1'}>
                        <div className={'container'}>
                            <div className={'label'}>
                                <span>（VRGDAs）</span>
                                <span>（Conway’s&nbsp;Game&nbsp;Of&nbsp;Life）</span>
                                BitLife&nbsp;Manufacturers
                            </div>
                        </div>
                    </div>
                    <div className={'icon-2'}>
                        <div className={'container'}>
                            <div className={'label'}>
                                BitLife
                            </div>
                        </div>
                    </div>
                    <div className={'icon-3'}>
                        <div className={'container'}>
                            <div className={'label'}>
                                Mine&nbsp;New{innerWidth > 750 ? <>&nbsp;</> : <br/>}Asset
                            </div>
                        </div>
                    </div>
                    <div className={'icon-4'}>
                        <div className={'container'}>
                            <div className={'label'}>
                                Mining{innerWidth > 750 ? <>&nbsp;</> : <br/>}Pool
                            </div>
                        </div>
                    </div>
                    <div className={'icon-5'}>
                        <div className={'container'}>
                            <div className={'label'}>
                                Mine&nbsp;Energy
                            </div>
                        </div>
                    </div>
                    <div className={'label-1'}>Sell</div>
                    <div className={'label-2'}>Analysoor</div>
                    <div className={'label-3'}>vPoW</div>
                    <div className={'arrow-1'}><div className="tail"></div></div>
                    <div className={'arrow-2'}><div className="tail"></div></div>
                    <div className={'arrow-3'}><div className="tail"></div></div>
                    <div className={'arrow-4'}><div className="tail"></div></div>
                </div>
            </div>
            <div className={'right-container'}>
                <div className={'title fourth-animate-2 animate__animated'}>
                    <div className={'text'}>
                        BitLifes power {innerWidth <= 750 ? <br/> : null} Cellula's  {innerWidth > 750 ? <br/> : null}
                        <span>vPoW</span> for {innerWidth <= 750 ? <br/> : null} fair{innerWidth > 750 ? <br/> : null}
                        asset distribution！
                    </div>
                </div>
                <div className={'tag-container fourth-animate-3 animate__animated'}>
                    {tags.map((item) => (
                        <div key={item} className={'tag'}>
                            <div className={'icon'}></div>
                            {item}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
})