import React, { useState } from "react";
import "./index.css";
import footerLogo from "src/assets/images/footer/footer-logo.png";
import dis from "src/assets/images/footer/dis.png";
import tele from "src/assets/images/footer/tele.png";
import twitter from "src/assets/images/footer/x.png";
import top from "src/assets/images/footer/top.png";

import { Email } from "src/api";
export default function Footer() {
  const [email, setEmail] = useState("");
  const handlerSubmit = async () => {
    if (!email) return;
    if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email)) {
      return alert("Invalid Email");
    }
    const result: any = await Email.saveEmail({
      email: email,
    });
    console.log(result);
    if (result.code === 200) {
      confirm("Submit successfully");
    } else {
      confirm(result.message);
    }
  };
  return (
    <>
      <div className="footer">
        <div className="content">
          <div className="left">
            <div className="left-top">
              <div className="footer-des">
                <img
                  src={footerLogo}
                  className="footer-logo"
                  alt="Cellula footer logo"
                />
                <div className="slogan">A Programmable Incentive Layer</div>
              </div>
              <div className="footer-media">
                <a
                  className="img-wrap"
                  href="https://x.com/cellulalifegame"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="" />
                </a>
                <a
                  className="img-wrap"
                  href="https://discord.gg/cellula-official"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={dis} alt="" />
                </a>
                <a
                  className="img-wrap"
                  href="https://t.me/CellulaOfficial"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={tele} alt="" />
                </a>
              </div>
            </div>
            <div className="left-bottom contact">
              <div className="title">Contact Us</div>
              <div className="action-area">
                <input
                  type="text"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <button onClick={handlerSubmit}> Submit to</button>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="right-main">
              <div className="right-left">
                <ul>
                  <li>
                    <a
                      href="https://medium.com/@cellulalifegame"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Medium
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@cellulalifegame"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      YouTube
                    </a>
                  </li>
                </ul>
              </div>
              <div className="right-right">
                <ul>
                  <li>
                    <a
                      href="https://app.dobby.market/cluster/8"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BitCell Marketplace
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://element.market/collections/bitlife-1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BitLife Marketplace
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.binance.com/en/square/profile/cellulalifegame"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Binance Square
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/blocksecteam/audit-reports/blob/main/solidity/blocksec_cellula_v1.0_signed.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Audit Report
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right-bottom">
              <div
                className="to-top"
                onClick={() => (window as any)["fullpage_api"].moveTo(1)}
              >
                <img src={top} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
