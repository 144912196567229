import "./index.scss"
import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import {gsap} from "gsap";
import tagIcon from "img/secord/tag-icon.png"
import cell1 from "img/secord/cell-1.png"
import cell2 from "img/secord/cell-2.png"
import sliding from "img/secord/sliding-block.png"
import energyBlock from "img/secord/energy-block.png"
import energyMark from "img/secord/energy-mark.png"
import energyBase from "img/secord/energy-base.png"
import robotList1 from "img/secord/robot-list1.png"
import baseList1 from "img/secord/base-list1.png"
import titleIcon from 'img/third/title-icon.png'
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import {BgPage} from "src/view/first/bg.tsx";
import * as echarts from 'echarts';

type ChildProps = {};

export const SecondPage = forwardRef((props: ChildProps, ref) => {
    const tagList = ['Permissionless', 'Competitive', 'Gamified', 'Transparent']
    const requestRef: any = useRef();
    const scaleRef: any = useRef();
    const boxShadowRef: any = useRef();
    const animateLineRef:any = useRef();
    const isPc: any = useRef();
    const getEcharts = () => {
        // 初始化 ECharts 实例
        var chart:any = echarts.init(document.getElementById('chart'));
        if (chart) {
            chart.dispose();
            chart = echarts.init(document.getElementById('chart'));
        }
        // 配置 ECharts
        var option = {
            grid: {
                top: '8%',
                left: '25%',
                right: '7%',
                bottom: '15%',
                containLabel: false
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                axisLine: {
                    //坐标轴轴线设置
                    show: true,
                    lineStyle: {
                        color: '#39F'
                    },
                    symbol: ['none', 'arrow']
                },
                axisLabel: {
                    // 坐标轴刻度文字的设置
                    show: true,
                    fontFamily: 'Snasm',
                    fontSize: 12,
                    color: '#337FC4', // 标签的颜色设置,默认取轴线的颜色
                },
                axisTick: {
                    // 坐标轴刻度设置
                    alignWithLabel: false,
                    length: 0
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(51, 153, 255, 0.10)',
                        width: 1,
                    }
                },
                data: ['1D', '2D', '3D', '4D', '5D', '6D', '7D']
            },
            yAxis: {
                type: 'value',
                scale: true,
                min: 0, // 设置 Y 轴的最小值
                max: 100000, // 设置 Y 轴的最大值
                interval: 25000, // 设置刻度间隔为 10
                axisLabel: {
                    // 坐标轴刻度文字的设置
                    show: true,
                    fontFamily: 'Snasm',
                    fontSize: 12,
                    color: '#FFC75A', // 标签的颜色设置,默认取轴线的颜色
                    // padding: [0, 10, 0, 0]
                },
                axisLine: {
                    //坐标轴轴线设置
                    show: true,
                    lineStyle: {
                        color: '#39F'
                    },
                    symbol: ['none', 'arrow']
                },
                splitLine: {
                    show: false,
                },
            },
            series: [
                {
                    data: [15000, 33000, 52000, 61000, 73000, 84000, 96000],
                    type: 'line',
                    symbol: 'circle', // 设置为圆形
                    symbolSize: 10, // 设置大小
                    itemStyle: {
                        color: '39F' // 设置颜色
                    },
                    areaStyle: { //区域填充样式
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(51, 153, 255, 0.20)" },
                                { offset: 1, color: "rgba(51, 153, 255, 0.00)" },
                            ]),
                        },
                    },
                }
            ],
            animationDuration: 2000,
        };
        chart.setOption(option);
        // 监听窗口的 resize 事件，调整图表大小
        window.addEventListener('resize', () => {
            chart.resize();
        });
    }
    const handleResize = () => {
        if (window.innerWidth > 750) {
            isPc.current = true
            requestRef.current = {
                top1: '28.3%',
                left1: '19%',
                top2: '70%',
                left2: '59%',
            }
            scaleRef.current = {
                x: '40px',
                y: '-20px',
            }
            boxShadowRef.current = '0px 0px 50px 0px #39F'
        } else if (window.innerWidth > 435)  {
            isPc.current = false
            requestRef.current = {
                top1: '25.3%',
                left1: '24.8%',
                top2: '69.4%',
                left2: '64.8%',
            }
            scaleRef.current = {
                x: '2%',
                y: '30%',
            }
            boxShadowRef.current = '0px 0px 20px 0px #39F'
        } else {
            isPc.current = false
            requestRef.current = {
                top1: '25.3%',
                left1: '25.9%',
                top2: '70%',
                left2: '64.8%',
            }
            scaleRef.current = {
                x: '2%',
                y: '30%',
            }
            boxShadowRef.current = '0px 0px 20px 0px #39F'
        }
    };
    useImperativeHandle(ref, () => ({
        startAnimation(){
            if (animateLineRef.current) {
                const elements:any = document.querySelectorAll(".loading")[0];
                setTimeout(() => {
                    elements.style.display = "none";
                }, 50)
                animateLineRef.current.restart()
                return
            }
            // 注册插件
            gsap.registerPlugin(MotionPathPlugin);
            animateLineRef.current = gsap.timeline();
            const mark = document.querySelectorAll('.mark')
            // 先把innerWord透明度置为0，等中间文字动画完成在把透明度置为1，因为如果直接在css样式里置为0，在鼠标移动圆圈的时候会有问题，移动完之后圆圈归位时文字不展示了
            animateLineRef.current.to(mark, {
                width: 0,
                duration: 0.5,
                ease: 'easeInOut',
            }); // "<"在上个动画开始，">"在上个动画结束
            const titleIcon = document.querySelectorAll('.title-icon')
            animateLineRef.current.to(titleIcon, {
                opacity: 1,
                duration: 0.01,
                ease: 'easeInOut',
            }, ">"); // "<"在上个动画开始，">"在上个动画结束
            // 添加 flipInX 动画
            // const centerWord = document.querySelectorAll('.center-word')
            // animateLineRef.current.fromTo(centerWord,
            //     { rotationY: -90, opacity: 0 },
            //     { rotationY: 0, opacity: 1, duration: 1, ease: 'back.out(1.7)' }
            // )
            // // // 添加 fadeInUp 动画
            // animateLineRef.current.fromTo(centerWord,
            //     { y: 50, opacity: 0 },
            //     { y: 0, opacity: 1, duration: 1, ease: 'power2.out' },
            //     "<" // 与上一个动画重叠
            // )
            // // 添加 fadeIn 动画
            // animateLineRef.current.fromTo(centerWord,
            //     { opacity: 0 },
            //     { opacity: 1, duration: 1, ease: 'power2.out' },
            //     "<"  // 与上一个动画重叠
            // );

            const tag = document.querySelectorAll('.tag')
            animateLineRef.current.to(tag, {
                opacity: 1,
                y: 0, // Move to original position
                duration: 1,
                ease: 'power2.out'
            }, ">"); // "<"在上个动画开始，">"在上个动画结束
            const gridList = document.querySelectorAll('.grid-list')
            animateLineRef.current.to(gridList, {
                opacity: 1,
                x: 0, // Move to original position
                duration: 1,
                ease: 'power2.out'
            }, "<"); // "<"在上个动画开始，">"在上个动画结束
            const cell:any = document.querySelectorAll('.cell')
            animateLineRef.current.to(cell, {
                opacity: 1,
                duration: 1,
                ease: 'power2.out'
            }, ">"); // "<"在上个动画开始，">"在上个动画结束
            const cell1 = document.querySelectorAll('.cell1')
            const cell2 = document.querySelectorAll('.cell2')
            animateLineRef.current.to([cell2], {
                duration: 0.5,                // 每个动画持续时间
                y: "10px",                 // 向左移动 10px
                onComplete: function () {    // 动画完成后执行
                    gsap.to([cell2], {
                        duration: 0.5,            // 每个动画持续时间
                        y: "-10px"               // 向右移动 10px
                    });
                }
            }, '<'); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to([cell1], {
                duration: 0.5,                // 每个动画持续时间
                x: "10px",                 // 向左移动 10px
                onComplete: function () {    // 动画完成后执行
                    gsap.to([cell1], {
                        duration: 0.5,            // 每个动画持续时间
                        x: "-10px"               // 向右移动 10px
                    });
                }
            }, '<'); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to(cell1, {
                top: requestRef.current.top1,
                left: requestRef.current.left1,
                duration: 1,
                ease: 'power2.out'
            }, "+=0.1"); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to(cell2, {
                top: requestRef.current.top2,
                left: requestRef.current.left2,
                duration: 1,
                ease: 'power2.out'
            }, "<"); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to(gridList, {
                duration: 0.5,                // 动画持续时间
                borderColor: "#3399FF",           // 边框颜色
                boxShadow: boxShadowRef.current,
                repeat: 2,                   // 无限循环
                yoyo: true,                   // 反向播放
                ease: "power1.inOut"
            }, ">") // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to([cell1, cell2, gridList], {
                opacity: 0,
                duration: 1,
                ease: 'power2.out'
            }, ">"); // "<"在上个动画开始，">"在上个动画结束
            const robotImg = document.querySelectorAll('.robot-img')
            const echartsWrap = document.querySelectorAll('.echarts-wrap')
            animateLineRef.current.to([robotImg], {
                opacity: 1,
                duration: 1,
                ease: 'power2.out'
            }, "<").call(() => {
                getEcharts()
            }); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to([echartsWrap], {
                opacity: 1,
                duration: 1,
                ease: 'power2.out'
            }, "<") // "<"在上个动画开始，">"在上个动画结束
            const robot = document.querySelectorAll('.robot')
            animateLineRef.current.to(robot, {
                duration: 2, // 动画持续时间，单位秒
                scale: 0,  // 缩小至原始大小的0%
                x: scaleRef.current.x,
                y: scaleRef.current.y,
                ease: "power1.inOut" // 缓动效果
            }, ">=2") // "<"在上个动画开始，">"在上个动画结束
            const energyWrap = document.querySelectorAll('.energy-wrap')
            const numWrap = document.querySelectorAll('.num-wrap')
            const numValue = document.querySelectorAll('.num-value')
            animateLineRef.current.to(numWrap, {
                duration: 1, // 动画持续时间，单位秒
                opacity: 1,
                ease: "power1.inOut" // 缓动效果
            }, "<=1") // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to(energyWrap, {
                duration: 2, // 动画持续时间，单位秒
                scale: 1,  // 缩小至原始大小的50%
                ease: "power1.inOut" // 缓动效果
            }, "<") // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to(numValue, {
                duration: 3,                // 动画持续时间，单位秒
                innerText: 101962,             // 最终的数字值
                snap: { innerText: 1 },     // 设置数字的递增步长
                ease: "power1.inOut",       // 缓动效果
            }, "<") // "<"在上个动画开始，">"在上个动画结束
            const robotList = document.querySelectorAll('.robot-list')
            animateLineRef.current.to(robotList, {
                duration: 1, // 动画持续时间，单位秒
                opacity: 1,
                ease: "power1.inOut",       // 缓动效果
            }, "<1.5") // "<"在上个动画开始，">"在上个动画结束

            const sliders1:any = document.querySelectorAll(".slider-first-batch");
            const sliders2:any = document.querySelectorAll(".slider-second-batch");
            const paths1:any = document.querySelectorAll(".path-first-batch");
            const paths2:any = document.querySelectorAll(".path-second-batch");
            animateLineRef.current.to(sliders1, {
                duration: 1, // 动画持续时间，单位秒
                opacity: 1,
                ease: "power1.inOut",       // 缓动效果
            }, ">"); // "<"在上个动画开始，">"在上个动画结束
            sliders1.forEach((slider:any, index:any) => {
                animateLineRef.current.to(slider, {
                    duration: 3,
                    repeat: -1,
                    ease: "power1.inOut",
                    motionPath: {
                        path: paths1[index],
                        align: paths1[index],
                        alignOrigin: [0.5, 0.5],
                        autoRotate: 180,
                    },
                    onRepeat: () => {
                        const rippleFirstBatchWrap:any = document.querySelectorAll(".ripple-first-batch");
                        for (let i = 0; i < Math.min(rippleFirstBatchWrap.length, 3); i++) {
                            rippleFirstBatchWrap[i].classList.add('ani')
                            setTimeout(() => {
                                rippleFirstBatchWrap[i].classList.remove('ani')
                            }, 1200)
                        }
                    }
                }, "<");
            });
            animateLineRef.current.to(sliders2, {
                duration: 1, // 动画持续时间，单位秒
                opacity: 1,
                ease: "power1.inOut",       // 缓动效果
            }, "<+1"); // "<"在上个动画开始，">"在上个动画结束
            sliders2.forEach((slider:any, index:any) => {
                animateLineRef.current.to(slider, {
                    duration: 3,
                    repeat: -1,
                    ease: "power1.inOut",
                    motionPath: {
                        path: paths2[index],
                        align: paths2[index],
                        alignOrigin: [0.5, 0.5],
                        autoRotate: 180,
                    },
                    onRepeat: () => {
                        const rippleSecondBatchWrap:any = document.querySelectorAll(".ripple-second-batch");
                        for (let i = 0; i < Math.min(rippleSecondBatchWrap.length, 3); i++) {
                            rippleSecondBatchWrap[i].classList.add('ani')
                            setTimeout(() => {
                                rippleSecondBatchWrap[i].classList.remove('ani')
                            }, 1200)
                        }
                    }
                }, "<");
            });
        },
        removeAnimation(){
            if (animateLineRef.current) {
                const elements:any = document.querySelectorAll(".loading")[0];
                elements.style.display = "block";
                animateLineRef.current.kill();
            }
        }
    }))
    useEffect(() => {
        handleResize()
        // 添加窗口尺寸变化的监听器
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className="second-page">
            <div className="loading"></div>
            <div className="left">
                <div className="center-word">
                    {
                        isPc.current ?
                            <div>
                                <div>Fair Asset</div>
                                <div>Distribution and</div>
                                <div>Liquidity Guidance</div>
                            </div>
                            :
                            <div>
                                <div>Fair Asset Distribution</div>
                                <div className="phone-bottom-word">and Liquidity Guidance</div>
                            </div>
                    }
                    <img src={titleIcon} className="title-icon" alt=""/>
                    <div className="mark-list">
                        <div className="mark-wrap">
                            <div className="mark mark1"></div>
                        </div>
                        <div className="mark-wrap">
                            <div className="mark mark2"></div>
                        </div>
                        <div className="mark-wrap">
                            <div className="mark mark3"></div>
                        </div>
                    </div>
                </div>

                <div className="tag-list">
                    {
                        tagList.map((tag: any, index: number) => (
                            <div className="tag" key={index}>
                                <img src={tagIcon} alt=""/>
                                <div className="label">{tag}</div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className="right">
                <div className="cell-list">
                    <img className="cell cell1" src={cell1} alt=""/>
                    <div className="grid-list">
                        {
                            Array.from({length: 9}).map((grid: any, index: number) => (
                                <div className={`single-grid grid-${index + 1}`} key={index}>

                                </div>
                            ))
                        }
                    </div>
                    <img className="cell cell2" src={cell2} alt=""/>
                </div>

                <div className="robot">
                    <div className="robot-img"></div>
                    <div className="echarts-wrap">
                        <div className="title1">Hashrate Curve</div>
                        <div className="title2">Hashrate</div>
                        <div className="echarts">
                            <div className="chart" id="chart"></div>
                        </div>
                    </div>
                </div>

                <div className="energy-block">
                    <div className="num-wrap">
                        <div className="num-value">0</div>
                    </div>
                    <div className="energy-wrap">
                        <img className="energy-stone" src={energyBlock} alt=""/>
                        <img className="energy-mark" src={energyMark} alt=""/>
                        <img className="energy-base" src={energyBase} alt=""/>
                    </div>
                    <div className="robot-list">
                        <img className="base-img-list" src={baseList1} alt=""/>
                        <img className="robot-list-img" src={robotList1} alt=""/>
                        <div className="svg-wrap">
                            <svg className="path-svg" width="100%robot-list-img" height="100%" viewBox="0 0 660 410">
                                <path className="path path1 path-first-batch" id="path" d="M210,100 L210,130 L45,130 L45,110"
                                      fill="none"
                                      strokeWidth="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider1 slider-first-batch" alt="Slider"/>
                            <div className="ripple-wrap ripple-wrap1 ripple-first-batch">
                                <div className="ripple"></div>
                            </div>

                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path2 path-second-batch" id="path"
                                      d="M250,125 L250,220 L190,220 L190,300 L118,300 L118,265" fill="none"
                                      strokeWidth="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider2 slider-second-batch" alt="Slider"/>
                            <div className="ripple-wrap ripple-wrap2 ripple-second-batch">
                                <div className="ripple"></div>
                            </div>

                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path3 path-first-batch" id="path"
                                      d="M300,145 L300,388 L254,388 L254,360" fill="none"
                                      strokeWidth="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider3 slider-first-batch" alt="Slider"/>
                            <div className="ripple-wrap ripple-wrap3 ripple-first-batch">
                                <div className="ripple"></div>
                            </div>

                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path4 path-second-batch" id="path"
                                      d="M350,145 L350,388 L404,388 L404,360" fill="none"
                                      strokeWidth="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider4 slider-second-batch" alt="Slider"/>
                            <div className="ripple-wrap ripple-wrap4 ripple-second-batch">
                                <div className="ripple"></div>
                            </div>

                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path5 path-first-batch" id="path"
                                      d="M400,125 L400,220 L460,220 L460,300 L538,300 L538,265" fill="none"
                                      strokeWidth="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider5 slider-first-batch" alt="Slider"/>
                            <div className="ripple-wrap ripple-wrap5 ripple-first-batch">
                                <div className="ripple"></div>
                            </div>

                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path6 path-second-batch" id="path"
                                      d="M440,100 L440,130 L620,130 L620,110" fill="none"
                                      strokeWidth="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider6 slider-second-batch" alt="Slider"/>
                            <div className="ripple-wrap ripple-wrap6 ripple-second-batch">
                                <div className="ripple"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
})