import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./index.css";
import left from "src/assets/images/fifth/left.png";
import middleBottom from "src/assets/images/fifth/middle-bottom.png";
import middle from "src/assets/images/fifth/middle.png";
import right from "src/assets/images/fifth/right.png";

import toUp from "src/assets/images/fifth/to_up.png";
import toRight from "src/assets/images/fifth/to_right.png";
import toDown from "src/assets/images/fifth/to_down.png";
import toLeft from "src/assets/images/fifth/to_left.png";

import leftM from "src/assets/images/fifth/m/left-m.png";
import middleBottomM from "src/assets/images/fifth/m/middle-bottom-m.png";
import middleM from "src/assets/images/fifth/m/middle-m.png";
import rightM from "src/assets/images/fifth/m/right-m.png";

type ChildProps = {};
export const FifthPage = forwardRef((props: ChildProps, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    startAnimation() {
      setShow(true);
    },
    removeAnimation() {
      setShow(false);
    },
  }));
  return (
    <>
      <div className="fifth">
        <div className="content">
          <div className="title">Technical Architecture</div>
          <div
            className={[
              "main",
              show
                ? "animate__animated animate__fadeInUpBig"
                : "animate__closed",
            ].join(" ")}
          >
            <div className="left">
              <img src={left} alt="" className="pc-unit" />
              <img src={leftM} alt="" className="m-unit" />
              <div className="pc-charge to-right-unit charge-right">
                <div className="point">
                  <div className="tail"></div>
                </div>
              </div>
              <div className="pc-charge to-left-unit charge-left">
                <div className="point">
                  <div className="tail"></div>
                </div>
              </div>
            </div>
            <div className="middle">
              <div className="middle-top">
                <img src={middle} alt="" className="pc-unit" />
                <img src={middleM} alt="" className="m-unit" />
              </div>
              <div className="middle-bottom">
                <img src={middleBottom} alt="" className="pc-unit" />
                <img src={middleBottomM} alt="" className="m-unit" />

                <div className="pc-charge to-up-unit charge-up">
                  <div className="point">
                    <div className="tail"></div>
                  </div>
                </div>
                <div className="pc-charge to-down-unit charge-down">
                  <div className="point">
                    <div className="tail"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <img src={right} alt="" className="pc-unit" />
              <img src={rightM} alt="" className="m-unit" />
              <div className="pc-charge to-left-unit charge-left">
                <div className="point">
                  <div className="tail"></div>
                </div>
              </div>
              <div className="pc-charge to-right-unit charge-right">
                <div className="point">
                  <div className="tail"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
