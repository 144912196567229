import './App.css'
import {ThirdPage} from "src/view/third/ThirdPage.tsx";
import {FourthPage} from "src/view/fourth/FourthPage.tsx";
import React, {useRef, useState} from "react";
import {FifthPage} from './view/fifth/index.tsx'
import {SixthPage} from './view/sixth/index.tsx'
import {FirstPage} from "src/view/first";
import {SecondPage} from "src/view/secord";
import ReactFullpage from '@fullpage/react-fullpage';
import {BgPage} from "src/view/first/bg.tsx";

function App() {
    const firstRef = useRef<any>(null);
    const secondRef = useRef<any>(null);
    const thirdRef = useRef<any>(null);
    const fourthRef = useRef<any>(null);
    const fifthRef = useRef<any>(null);
    const sixthRef = useRef<any>(null);

    // 执行动画的逻辑
    const startAnimationForSection = (section: number) => {
        switch (section) {
            case 0:
                firstRef.current?.startAnimation();
                break;
            case 1:
                secondRef.current?.startAnimation();
                break;
            case 2:
                thirdRef.current?.startAnimation();
                break;
            case 3:
                fourthRef.current?.startAnimation();
                break;
            case 4:
                fifthRef.current?.startAnimation();
                break;
            case 5:
                sixthRef.current?.startAnimation();
                break;
            default:
                break;
        }
    };

    // 清除动画的逻辑
    const removeAnimationForSection = (section: number) => {
        switch (section) {
            case 0:
                firstRef.current?.removeAnimation();
                break;
            case 1:
                secondRef.current?.removeAnimation();
                break;
            case 2:
                thirdRef.current?.removeAnimation();
                break;
            case 3:
                fourthRef.current?.removeAnimation();
                break;
            case 4:
                fifthRef.current?.removeAnimation();
                break;
            case 5:
                sixthRef.current?.removeAnimation();
                break;
            default:
                break;
        }
    };
    return (
        <>
            <BgPage></BgPage>
            <ReactFullpage
                credits={{enabled: false}}
                afterLoad={(origin, destination, direction) => {
                    const newSectionIndex = destination.index;
                    if (origin) {
                        removeAnimationForSection(origin.index);
                    }
                    startAnimationForSection(newSectionIndex);
                }}
                render={() => (
                    <ReactFullpage.Wrapper>
                        <div className="section">
                            <FirstPage ref={firstRef}/>
                        </div>
                        <div className="section">
                            <SecondPage ref={secondRef}/>
                        </div>
                        <div className="section">
                            <FourthPage ref={thirdRef}/>
                        </div>
                        <div className="section">
                            <ThirdPage ref={fourthRef}/>
                        </div>
                        <div className="section">
                            <FifthPage ref={fifthRef}/>
                        </div>
                        <div className="section">
                            <SixthPage ref={sixthRef}/>
                        </div>
                    </ReactFullpage.Wrapper>
                )}
            />
        </>
    )
}

export default App
