import React, { useRef, useState, useEffect } from 'react';
import "./bg.scss"
import {gsap} from "gsap";

export const BgPage: React.FC = function () {
    const cellSize = 10;
    const interval = 100;
    const [mousePosition, setMousePosition] = useState({ x: -1, y: -1 });
    const progressRef:any = useRef({x: 0, y: 0});
    useEffect(() => {
        const updateMousePosition = (event: MouseEvent) => {
            let obj:any = {
                x: event.clientX,
                y: event.clientY
            }
            progressRef.current = obj
        };
        const viewDiv:any = document.querySelector('.view');
        const canvas = document.createElement('canvas');
        viewDiv.appendChild(canvas);
        const ctx: any = canvas.getContext('2d');
        if (!ctx) return;

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const rows = Math.floor(canvas.height / cellSize);
        const columns = Math.floor(canvas.width / cellSize);
        function createGrid(): boolean[][] {
            const grid: boolean[][] = [];

            for (let y = 0; y < rows; y++) {
                grid[y] = [];

                for (let x = 0; x < columns; x++) {
                    grid[y][x] = Math.random() >= 0.5;
                }
            }
            return grid;
        }

        function drawGrid(grid: boolean[][]): void {
            if (!ctx) return;
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            for (let y = 0; y < rows; y++) {
                for (let x = 0; x < columns; x++) {
                    if (grid[y][x]) {
                        ctx.strokeStyle = 'rgb(129, 125, 115)';
                        ctx.lineWidth = 1;
                        ctx.strokeRect(x * cellSize, y * cellSize, cellSize, cellSize);
                    }
                }
            }
        }

        function countNeighbors(grid: boolean[][], x: number, y: number): number {
            let count = 0;
            for (let yOffset = -1; yOffset <= 1; yOffset++) {
                for (let xOffset = -1; xOffset <= 1; xOffset++) {
                    if (yOffset === 0 && xOffset === 0) continue;

                    const newY = (y + yOffset + rows) % rows;
                    const newX = (x + xOffset + columns) % columns;

                    if (grid[newY][newX]) count++;
                }
            }

            return count;
        }

        function updateGrid(grid: boolean[][]): boolean[][] {
            const newGrid: boolean[][] = [];

            for (let y = 0; y < rows; y++) {
                newGrid[y] = [];

                for (let x = 0; x < columns; x++) {
                    const neighbors = countNeighbors(grid, x, y);
                    const isAlive = grid[y][x];

                    newGrid[y][x] = neighbors === 3 || (isAlive && neighbors === 2);
                }
            }

            return newGrid;
        }

        function gameLoop(grid: boolean[][]): void {
            drawGrid(grid);

            const gradient: any = ctx.createRadialGradient(
                progressRef.current.x,
                progressRef.current.y,
                0,
                progressRef.current.x,
                progressRef.current.y,
                200
            );
            gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
            gradient.addColorStop(1, 'rgba(0, 0, 0, 1)');

            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            const newGrid = updateGrid(grid);
            // Set the body background image
            const canvasImage = canvas.toDataURL();
            viewDiv.style.backgroundImage = `url(${canvasImage})`;
            viewDiv.style.backgroundAttachment = 'fixed';
            viewDiv.style.backgroundSize = 'contain';
            viewDiv.style.backgroundRepeat = 'no-repeat';
            setTimeout(() => gameLoop(newGrid), interval);
        }

        const grid = createGrid();
        gameLoop(grid);
        document.body.addEventListener('mousemove', updateMousePosition);
    }, []);
    return (
        <div className={'view'}>

        </div>
    )
}