import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import 'amfe-flexible/index.js'
import './styles/font-family/font.css'
import './styles/element/index.scss'
import 'animate.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
    <App />
)
